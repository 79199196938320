// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/login-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-0a5185ce]  .v-input fieldset{background-color:#fff}[data-v-0a5185ce]  .theme--light.v-counter,[data-v-0a5185ce]  .v-input:not(.error--text) .v-messages__message{color:#fff}[data-v-0a5185ce]  .v-messages__message{white-space:pre-line;font-weight:700;line-height:1.3}.start-bg-image[data-v-0a5185ce]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50% scroll;background-size:cover}.custom-password-reset-h-padding[data-v-0a5185ce]{padding:1em 0}.icon-height[data-v-0a5185ce]{height:1.5em}.text-icon[data-v-0a5185ce]{display:inline-block;vertical-align:middle;max-width:100%}div.panel.register[data-v-0a5185ce]{background-color:rgba(0,0,0,.5);border-style:none;border-radius:.5em;margin-top:5%;color:#fff;padding:1rem}.v-btn.new-password-form__btn[data-v-0a5185ce]{min-width:140px;padding:0 15px}.browser-links a[data-v-0a5185ce]{color:#fff}", ""]);
// Exports
module.exports = exports;
