import { render, staticRenderFns } from "./dataterms.html?vue&type=template&id=36f01c2c&scoped=true&external"
import script from "./dataterms.ts?vue&type=script&lang=ts&external"
export * from "./dataterms.ts?vue&type=script&lang=ts&external"
import style0 from "./dataterms.scss?vue&type=style&index=0&id=36f01c2c&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f01c2c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
