// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/start-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-0c1c638c]  .v-input fieldset{background-color:#fff}[data-v-0c1c638c]  .theme--light.v-counter,[data-v-0c1c638c]  .v-icon.mdi-checkbox-blank-outline{color:#fff}[data-v-0c1c638c]  .registration_form-checkboxes .v-input--checkbox{justify-content:center}[data-v-0c1c638c]  .registration_form-checkboxes .v-input__control{flex-grow:0;width:auto}[data-v-0c1c638c]  .registration_form-checkboxes .v-messages{min-height:1px}[data-v-0c1c638c]  .registration_form-checkboxes .v-messages__message{text-align:center}[data-v-0c1c638c]  .v-messages__message{white-space:pre-line;font-weight:700;line-height:1.3}.start-bg-image[data-v-0c1c638c]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50% scroll;background-size:cover}.custom-password-reset-h-padding[data-v-0c1c638c]{padding:.6em 0 1.5em 0}.custom-h-padding[data-v-0c1c638c]{padding:.2em 0 1em 0}.icon-height[data-v-0c1c638c]{height:1.5em}.text-icon[data-v-0c1c638c]{display:inline-block;vertical-align:middle;max-width:100%}div.panel.register[data-v-0c1c638c]{background-color:rgba(0,0,0,.5);border-style:none;border-radius:.5em;margin-top:5%;color:#fff;padding:1rem}.browser-links a[data-v-0c1c638c],.registration_form-checkboxes a[data-v-0c1c638c]{color:#fff}.multi-line-text[data-v-0c1c638c],.pre-line[data-v-0c1c638c]{white-space:pre-line}", ""]);
// Exports
module.exports = exports;
