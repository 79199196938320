// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/start-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-dd3007dc]  .v-input fieldset{background-color:#fff}.start-bg-image[data-v-dd3007dc]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50% scroll;background-size:cover}.custom-password-reset-h-padding[data-v-dd3007dc]{padding:2.25em 0}.icon-height[data-v-dd3007dc]{height:1.5em}.text-icon[data-v-dd3007dc]{display:inline-block;vertical-align:middle;max-width:100%}div.panel.register[data-v-dd3007dc]{background-color:rgba(0,0,0,.5);border-style:none;border-radius:.5em;margin-top:5%;color:#fff;padding:1rem}.browser-links a[data-v-dd3007dc]{color:#fff}", ""]);
// Exports
module.exports = exports;
