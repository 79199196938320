// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/start-bg.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "a[data-v-01e30ed6]{text-decoration:none}a[data-v-01e30ed6]:hover,footer a[data-v-01e30ed6]{color:#000}h4[data-v-01e30ed6]{font-size:1.25rem}.custom-text-size-for-logo[data-v-01e30ed6]{font-size:4rem!important;font-weight:300;line-height:6rem;letter-spacing:-.015625em!important;font-family:Roboto,sans-serif!important}.start-bg-image[data-v-01e30ed6]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50% scroll;background-size:cover}.whitespace-pre[data-v-01e30ed6]{white-space:pre-wrap}.custom-home-h1-padding[data-v-01e30ed6]{padding-top:2%;padding-bottom:120px;padding-left:2%}.custom-home-h2-padding[data-v-01e30ed6]{padding-top:100px;padding-bottom:80px}.text-icon[data-v-01e30ed6]{display:inline-block;vertical-align:middle;max-width:100%}.v-btn.price-button[data-v-01e30ed6]{height:54px}", ""]);
// Exports
module.exports = exports;
