// Pure `toApi` (send to backend model) when send email to user
export interface EmailData {
  from?: string;
  userEmail: string;
  userName?: string;
  subject?: string;
  siteHost?: string;
  organizationName?: string;
  type: EmailType;
  phone: string;
  message?: string;
  documentName?: string;
  documentId?: string;
  threadId?: string;
  dueDate?: string;
  timezoneOffset?: number;
}

export enum EmailType {
  addEmployee = 'addEmployee',
  registration = 'registration',
  registrationViaEmail = 'registrationViaEmail',
  forgetPassword = 'forgetPassword',
  newEmployeeInCompany = 'newEmployeeInCompany',
  questionAnswer = 'questionAnswer',
}

export interface PasswordData {
  userId?: string;
  email?: string;
  password: string;
  code?: string;
}
export interface ConfirmEmailDto {
  email: string;
  code: string;
}
