import { Credetials } from '@/shared/model/credentials';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const authModule = namespace('auth');
@Component({
  components: {},
})
export default class Home extends Vue {
  @authModule.Getter('getAccount')
  private getAccount!: Credetials;
  @authModule.Action('getUser')
  private actionGetAccount!: any;
  mounted() {
    this.actionGetAccount();
  }
}
