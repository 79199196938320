import { render, staticRenderFns } from "./home-footer.html?vue&type=template&id=c5b09054&scoped=true&external"
import script from "./home-footer.ts?vue&type=script&lang=ts&external"
export * from "./home-footer.ts?vue&type=script&lang=ts&external"
import style0 from "./home-footer.scss?vue&type=style&index=0&id=c5b09054&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5b09054",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
