import { Component, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { PasswordData } from '@/shared/model/smallPayloadModels/emailData';
import HomeFooter from '../home-footer/home-footer.vue';

const logger = new Logger('new-password');
const userModule = namespace('user');
const authModule = namespace('auth');

@Component({
  components: { HomeFooter },
})
export default class NewPasswordView extends Vue {
  @Ref('new-password-form')
  private refNewPasswordForm!: any;

  @userModule.Action('setNewPasswordViaEmail')
  private actionSetNewPasswordViaEmail!: any;
  @authModule.Action('login')
  private actionLogin!: any;
  @authModule.Getter('isLoggedIn')
  private isLoggedIn!: boolean;

  isFormValid = true;

  password = '';
  confirmPassword = '';
  showPassword = false;
  showConfirmPassword = false;

  newPasswordInfo: PasswordData = {
    code: '',
    password: '',
    userId: '',
    email: '',
  };

  private strongRegex = new RegExp('^(?=.*[a-z,ä,ö,ü])(?=.*[A-Z,Ä,Ö,Ü])(?=.*[0-9])(?=.{8,})'); // same rules as at `https://neu.easy-docs.de/`  (without special characters)

  get isPasswordValid() {
    const isTrueResult = this.rules.strongPassword(this.password).toString().length == 4; // 'TRUE` not long validation error message
    return isTrueResult;
  }

  get rules() {
    return {
      required: (value: any) => !!value || this.$t('messages.password_required'),
      strongPassword: (value: any) => (!!value && this.strongRegex.test(value)) || this.$t('messages.password_strong'),
      samePass: (value: any) =>
        (!!value && value && this.password == this.confirmPassword) || this.$t('messages.same_password'),
    };
  }

  mounted() {
    this.newPasswordInfo.code = this.$route.query?.code?.toString();
    this.newPasswordInfo.userId = this.$route.query?.userId?.toString();
    this.newPasswordInfo.email = this.$route.query?.email?.toString();
  }

  async cancel() {
    this.$router.push('/');
  }

  async setNewPassword() {
    const isFormValidResult = await this.refNewPasswordForm.validate();

    if (isFormValidResult) {
      this.newPasswordInfo.password = this.password;
      await this.actionSetNewPasswordViaEmail(this.newPasswordInfo).then(async () => {
        if (this.isLoggedIn) {
          this.$router.push('/settings/company/employee');
        } else {
          await this.actionLogin();
        }
      });
    }
  }
}
