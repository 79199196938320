import { Component, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { EmailData, EmailType } from '@/shared/model/smallPayloadModels/emailData';
import HomeFooter from '../home-footer/home-footer.vue';

const logger = new Logger('forget-password');
const userModule = namespace('user');

@Component({
  components: { HomeFooter },
})
export default class ForgetPasswordView extends Vue {
  @Ref('forget-password-form')
  private refForgetPasswordForm!: any;

  @userModule.Action('sendEmail')
  private actionSendEmail!: any;

  email = '';
  isFormValid = true;
  isSubmitted = false;

  isEmailSent = false;
  emailNotFoundError = false;

  get emailRules() {
    return [
      (v: string) => {
        return (
          this.emailValidation(v) ||
          !this.isSubmitted || // prevent to show error before click `submit` button
          (this.isSubmitted && this.emailValidation(v)) ||
          this.$i18n.t('messages.email_required')
        ); // i18n.tc(`messages.email_required`) is not working here, just shows `name_required_message` key
      },
    ];
  }

  emailValidation(v: string) {
    return (
      v &&
      v.trim().length >= 6 &&
      v.trim().length < 256 && // with domain minimum 6 chars (https://stackoverflow.com/a/9034077)
      /^[^@]+@[^@]{2,}\.[^@]{2,}$/.test(v)
    ); // simple validation because of https://stackoverflow.com/a/202528 (possible typos and made-up emails)
  }

  async sendEmail() {
    this.isSubmitted = true;
    const isFormValidResult = await this.refForgetPasswordForm.validate();

    if (isFormValidResult) {
      const payload: EmailData = {
        userEmail: this.email,
        phone: '',
        type: EmailType.forgetPassword,
        siteHost: window.location.host,
      };
      await this.actionSendEmail(payload)
        .then((result: any) => {
          this.emailNotFoundError = false;
          this.isEmailSent = true;
        })
        .catch((err: any) => {
          if (err.response.status === 404) {
            this.emailNotFoundError = true;
          }
          console.error(err);
        });
    }
  }
}
